.infoText {
  margin-bottom: 40px;
  color: var(--donor-dark-gray);
}

.infoTextBold {
  font-weight: 500;
}

.signOut {
  text-align: center;
  margin-top: 20px;
}
