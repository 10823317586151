.container {
  margin: 30px;
  direction: rtl;
  text-align: center;
}

.image {
  height: 100px;
  margin-bottom: 20px;
}

.popupText {
  font-size: 16px;
  text-align: center;
  white-space: pre-line;
  color: var(--donor-dark-gray);
}

.popupTextTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 25px;
}

.popupTextContent {
  line-height: 20px;
}

.approveButton {
  width: 104px;
  height: 48px;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.backButton {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.buttonContainer {
  margin-top: 20px;
}
