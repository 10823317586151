button.zm-btn {
  background: var(--donor-primary) !important;
  transition: all 0.1s ease !important;
  &:hover {
    opacity: 0.9;
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(-1px);
  }
}

.button {
  font-size: 16px;
  height: 44px;
  border-radius: 6px !important;
}

.textButton {
  font-size: 14px;
  color: var(--donor-link);
  text-decoration: underline;
}

.buttonText {
  font-size: 18px;
  font-weight: normal;
}
