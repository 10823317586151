.hospitalCard {
  margin-bottom: 0px;
}

.dayTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--donor-dark-gray);
}

.hospitalName {
  font-size: 16px;
  margin-top: 20px;
  margin-right: 5px;
  color: var(--donor-light-black);
}

.storyBackground {
  padding: 20px;
  height: 100vh;
}
