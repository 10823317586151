.notesTitle {
  font-weight: 500;
  margin-top: 30px;
  color: var(--donor-light-black);
}

.notesText {
  margin: 8px 10px 8px 0;
  font-size: 0.9rem;
  color: var(--donor-dark-gray);
}

.notesDetails {
  font-size: 14px;
  line-height: 20px;
  color: var(--donor-gray);
  margin-right: 10px;
}
