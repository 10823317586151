.screen {
  display: flex;
  flex-direction: column;
}

.questionnaireSection {
  padding: 30px;
  overflow-y: scroll;
  background-color: var(--donor-light);
}

.questionnaireTitle {
  font-weight: 500;
  font-size: 18px;
  color: var(--donor-light-black);
}

.questionnaireText {
  font-size: 16px;
  line-height: 22px;
  color: var(--donor-gray);
}

.confirmButtonContainer {
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--donor-dark-gray);
}
