// add global styling here

html,
body,
#root {
  padding: 0;
  margin: 0;
  direction: rtl;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: var(--donor-white);
  color: var(--donor-dark-gray);

  font-family: var(--font-family-content);
  font-weight: var(--font-content-weight-normal);
  font-size: var(--text-size-md);
  line-height: var(--font-line-height-normal);
  -webkit-font-smoothing: antialiased;

  direction: rtl;
}

.safe-screen-padding-top {
  padding-top: env(safe-area-inset-top);
}

.safe-screen-padding-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

.safe-screen-padding-left {
  padding-left: env(safe-area-inset-left);
}

.safe-screen-padding-right {
  padding-right: env(safe-area-inset-right);
}
