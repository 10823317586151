.screenSection {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.screenContent {
  flex: 1;
}

.loginIllustration {
  width: 60vw;
  margin-top: -10px;
}

.title {
  text-align: center;
  color: var(--donor-primary);
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 700;
}

.textButton {
  padding-top: 25px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.textButtonColor {
  color: var(--donor-gray);
  text-decoration: none;
}

.alternativeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 25px 0 10px 0;
}

.alternativeTitle {
  margin: 0 5px;
  display: inline-block;
  line-height: 24px;
  color: var(--donor-dark-gray);
}

.alternativeButton {
  text-decoration: underline;
  color: var(--donor-secondary);
}
