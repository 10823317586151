.imageContainer {
  width: 100%;
  height: 160px;
  background: transparent
    linear-gradient(
      66deg,
      #fbbae2 0%,
      #fbc5e5 13%,
      #fcd9eb 42%,
      #fce5ee 57%,
      #fdfdfd 100%
    )
    0% 0% no-repeat padding-box;
  text-align: center;
}

.illustration {
  height: 160px;
}

.content {
  font-size: 16px;
  padding: 20px 40px;
  color: var(--donor-light-black);
}

.title {
  font-weight: 500;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.contentLine {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

.contact {
  display: block;
  height: 100%;
  width: 100%;
}

.link {
  color: inherit;
  text-decoration: inherit;
}

.underline {
  text-decoration: underline;
  color: var(--donor-link);
}

.phonelink {
  direction: ltr;
}
