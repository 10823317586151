.moreInfo {
  margin: 30px 40px 30px 40px;
  color: var(--donor-dark-gray);
}

.moreInfoTitle {
  font-size: 20px;
  font-weight: 500;
}

.tipList {
  margin-top: 10px;
  padding-right: 25px;
  list-style-position: outside;
}
