@font-face {
  font-family: "Arimo";
  src: url("../assets/fonts/Arimo/Arimo-VariableFont_wght.ttf")
    format("truetype");
}
@font-face {
  font-family: "David Libre";
  src: url("../assets/fonts/Secular_One/SecularOne-Regular.ttf")
    format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap");
