.component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
}

.bottomContainer {
  display: flex;
  justify-content: space-evenly;
}
