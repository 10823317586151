.title {
  box-sizing: border-box;
  font-size: 40px;
  font-weight: 700;
  color: var(--donor-secondary);
  padding: 40px;
  background-image: linear-gradient(
    63deg,
    #fbbae2,
    #fbc5e5,
    #fcd9eb,
    #fce5ee,
    #fdeaf0
  );
}

.content {
  padding: 40px;
}
