.pinkContainer {
  position: relative;
  font-size: 22px;
  background: transparent
    linear-gradient(
      39deg,
      #fbbae2 0%,
      #fbc5e5 13%,
      #fcd9eb 42%,
      #fce5ee 57%,
      #fdfdfd 100%
    )
    0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.welcome {
  height: 142px;
}

.illustration {
  height: 142px;
  position: absolute;
  left: 35px;
}

.welcomeTitle {
  margin-right: 10px;
  font-size: 20px;
  color: var(--donor-light-black);
  max-width: 60%;
  margin-top: 30px;
  line-height: 30px;

  position: relative;
  z-index: 10;
}

.welcomeText {
  font-size: 32px;
  font-weight: 700;
}

.appointmentDetails {
  box-shadow: 0px 5px 28px #cb007b29;
}

.card {
  background-color: white;
  border: 1px solid #dadee0;
  border-radius: 10px;
}

.detailsTitleContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--donor-secondary) 1;
  padding: 15px;
  background-color: #fdf5f8;
  border-radius: 16px 16px 0px 0px;
}

.detailsTitle {
  font-size: 18px;
  color: var(--donor-light-black);
  font-weight: 500;
  flex: 1;
}

.detailsText {
  margin-top: 15px;
  padding: 15px;
}

.detailLabel {
  font-size: 14px;
  line-height: 12px;
  color: var(--donor-gray);
}

.detailValue {
  font-size: 18px;
  color: var(--donor-light-black);
}

.link {
  font-size: 14px;
  line-height: 15px;
  text-decoration: underline;
  color: var(--donor-link);
  margin-bottom: 25px;
  cursor: pointer;
}

.link > a {
  all: unset;
  color: var(--donor-link) !important;
}

.icon {
  height: 17px;
  margin-left: 10px;
}

.popupContent {
  font-size: 16px;
  line-height: 19px;
  color: var(--donor-dark-gray);
}

.popupPhoneNumber {
  color: var(--donor-link);
  cursor: pointer;
  margin-inline-start: 5px;
}
