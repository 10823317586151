.appHeader {
  //border-bottom: 1px solid var(--donor-light-gray);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  padding: 0 5px;
  justify-content: space-between;
}

.title {
  flex: 1;
  text-align: center;
  font-size: 14pt;
  color: var(--donor-secondary);
  position: fixed;
  width: 100%;
  font-weight: normal;
}

.logoImage {
  padding-top: 8px;
}

.rightButton {
  z-index: 10;
}

.listItem {
  min-width: 50vw;
}

.version {
  font-size: 14px;
  margin-right: 20px;
  padding-bottom: 10px;
}

.login {
  z-index: 20;
  margin-left: 15px;
  display: flex;
}

.login_text {
  color: var(--donor-link);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  padding-left: 6px;
}

.zmLogoImage {
  height: 84px;
  width: 80px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 69px;
}
