.subtitle {
  color: var(--donor-primary);
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
}

.crucialInformation {
  margin-top: 60px;
}

.button {
  margin-top: 20px;
}

.bloodTypeButton {
  direction: ltr;
}
