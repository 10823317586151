.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.image {
  max-height: 30vh;
  @media only screen and (max-width: 400px) {
    max-height: 20vh;
  }
}

.title {
  color: var(--donor-primary);
  font-size: 32px;
  font-weight: bold;
  margin: 20px 0;
  @media only screen and (max-width: 400px) {
    font-size: 28px;
  }
}

.pageText {
  flex: 1;
}

.content {
  direction: rtl;
  line-height: 24px;
  font-size: 18px;
  color: var(--donor-dark-gray);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 40px;
}

.link {
  margin-top: 5px;
  font-weight: normal;
}

.buttonText {
  margin-top: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.boldText {
  font-weight: 400;
}

.textButton {
  color: var(--donor-secondary);
  margin-right: 5px;
  font-size: 18px;
}

.dots {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: var(--donor-bright-green);
  margin: 8px;
  border-radius: 10px;
  opacity: 0.3;
}

.dotActive {
  opacity: 1 !important;
}

.actionButton {
  margin: 20px 40px;
}
