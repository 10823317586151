.bookDonationScreen {
  display: flex;
  flex-direction: column;
  background-color: var(--donor-light-pink);
}

.welcomeBox {
  background: transparent
    linear-gradient(
      66deg,
      #fbbae2 0%,
      #fbc5e5 13%,
      #fcd9eb 42%,
      #fce5ee 57%,
      #fdfdfd 100%
    )
    0 0 no-repeat padding-box;
  height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.welcomeTitle {
  flex: 1;
  font-size: 22px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  z-index: 10;
  max-width: 60%;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--donor-light-black);
}

.welcomeText {
  z-index: 10;
  font-size: 20px;
  color: var(--donor-light-black);
}

.dropdownContainer {
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
}

.dropdown {
  width: 100%;
}

.donationsCard {
  background-color: white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -5px 20px #cb007b20;
  flex: 1;
}

.loading {
  padding: 40px;
  text-align: center;
}

.dropAnimation {
  height: 86px;
}

.loadingText {
  margin-top: 15px;
  color: var(--donor-light-black);
  font-size: 16px;
}

.noAppointments {
  padding: 40px;
  text-align: center;
  font-size: 16px;
  color: var(--donor-light-black);
}

.donationDayWrapper {
  padding: 20px 20px;
  border-bottom: 3px solid var(--donor-light-pink);
}

.noMoreAppointments {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 50px;
  font-size: 16px;
  color: var(--donor-light-black);
}

.noAppointmentsImage {
  width: 64px;
  margin-bottom: 15px;
}

// AppointmentPicker
.availableAppointmentsTitle {
  color: var(--donor-gray);
  font-size: 20px;
  padding: 20px 20px 0px 20px;
}

.appointmentPickerWrapper {
  padding-top: 20px;
  border-bottom: 3px solid var(--donor-light-pink);
}

.appointmentPickerWrapper:last-child {
  border-bottom: none;
}
