:root {
  caret-color: transparent; /* Hides blinking cursor */
  -webkit-user-select: none; /* Prevents selecting text */
}

//colors-------------------------------------------------------
// See also : src/utils/colors for tsx colors
:root {
  --donor-primary: #4caf51;
  --donor-bright-green: #a2d45e;
  --donor-selected-green: #a2d45e1f;
  --donor-secondary: #c7007d;
  --donor-light-pink: #fdf5f8;
  --donor-light-black: #1e1f1e;
  --donor-dark-gray: #474747;
  --donor-gray: #7e7e7e;
  --donor-light-gray: #dadee0;
  --donor-light: #f6f6f6;
  --donor-white: #ffffff;
  --donor-error: #cb0027;
  --donor-link: #1e88e5;
}

//fonts--------------------------------------------------------
:root {
  --font-family-titles: "Rubik", serif;
  --font-title-weight-normal: 400;
  --font-title-weight-bold: 500;
  --font-title-weight-bolder: 700;

  --font-family-content: "Rubik", serif;
  --font-content-weight-normal: 400;
  --font-content-weight-bold: 700;

  --font-line-height-small: 110%;
  --font-line-height-normal: 160%;

  //copied form here
  //https://codyhouse.co/blog/post/css-custom-properties-vs-sass-variables

  // body font size
  --text-base-size: 1em;

  --text-scale-ratio: 1.1;
  --text-size-xs: calc(
    (1em / var(--text-scale-ratio)) / var(--text-scale-ratio)
  );
  --text-size-sm: calc(var(--text-size-xs) * var(--text-scale-ratio));
  --text-size-md: calc(
    var(--text-size-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-size-lg: calc(var(--text-size-md) * var(--text-scale-ratio));
  --text-size-xl: calc(var(--text-size-lg) * var(--text-scale-ratio));
  --text-size-xxl: calc(var(--text-size-xl) * var(--text-scale-ratio));
  --text-size-xxxl: calc(var(--text-size-xxl) * var(--text-scale-ratio));
}

//layouts-------------------------------------------------------
:root {
  // elements padding/margin
  --layout-distance-small: 10px;
  --layout-distance-normal: 15px;
  --layout-distance-large: 20px;
  --layout-distance-xl: 50px;
  --layout-distance-xxl: 100px;

  --text-direction: ltr;

  // this affects the "Input component" when "type == email",
  --input-email-text-align: end;
}

//other styling rules------------------------------------------
:root {
  --shadow-normal: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
  --shadow-string: 0 1px 4px 1px rgba(0, 0, 0, 0.3);

  --border-radius-normal: 5px;
  --border-radius-strong: 15px;
  --border-radius-stronger: 20px;
  --border-radius-circlular: 30px;

  --transition-speed-fast: 100ms;
  --transition-speed-normal: 200ms;
}
